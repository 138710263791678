import React, { useState } from "react";
import Config from "../Config";

const Navbar = ({ getTask }) => {
  const [textBoxValue, setTextBoxValue] = useState("");

  const handleLogout = () => {
    // const cognitoDomain = "your-cognito-domain";
    // const clientId = "your-client-id";
    // const region = "your-region"; // e.g., 'us-east-1'
    // const logoutUri = encodeURIComponent("http://localhost:3000/"); // replace with your redirect URI

    const logoutUrl = Config.LogoutUrl;
    window.location.href = logoutUrl;
  };

  return (
    <div className="top-navbar">
      <div className="center-container">
      </div>
      <div className="sign-out-group">
        <button
          className="btn btn-danger sign-out-button"
          onClick={handleLogout}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Navbar;
