import axios from "axios";
import Config from "../Config";

const API_BASE_URL = Config.APIUrl;

const setBusyCursor = () => {
  document.body.style.cursor = 'wait';
};

const setDefaultCursor = () => {
  document.body.style.cursor = 'default';
};

const redirectToLogin = () => {
  // Replace '/login' with your login route
  window.location.href = Config.LoginUrl;
};

const ApiService = {
  // GET requests
  get: async (endpoint, headers = {}) => {
    setBusyCursor();
    try {
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`, {
        headers: {
          ...headers,
        },
      });
      setDefaultCursor();
      return response.data;
    } catch (error) {
      setDefaultCursor();
      if (error.response && error.response.status === 401) {
        redirectToLogin();
      }
      console.error("Error making GET request:", error);
      throw error.response ? error.response.data : error;
    }
  },

  // POST requests
  post: async (endpoint, data, headers = {}) => {
    setBusyCursor();
    try {
      const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data, {
        headers: {
          ...headers,
        },
      });
      setDefaultCursor();
      return response.data;
    } catch (error) {
      setDefaultCursor();
      if (error.response && error.response.status === 401) {
        redirectToLogin();
      }
      console.error("Error making POST request:", error);
      throw error.response ? error.response.data : error;
    }
  },
};

export default ApiService;
