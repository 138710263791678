import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Config from "../Config";

const uploadDocument = (client) => async (bucket, key, file) => {
  const input = {
    Bucket: bucket,
    Key: key,
    Body: file,
  };
  const command = new PutObjectCommand(input);

  var response = await client.send(command);
  if (response.$metadata.httpStatusCode !== 200) {
    throw Error(response);
  }
};

const s3ClientFactory = (token) => {
  // console.log('token', token)
  const creds = fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: Config.DeployRegion }),
    identityPoolId: Config.CognitoIdentityPoolId,
    logins: { [Config.CognitoId]: token },
  });

  const client = new S3Client({
    region: Config.DeployRegion,
    credentials: creds,
  });

  return {
    uploadDocument: uploadDocument(client),
  };
};

export default s3ClientFactory;
