import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/global.css";
import React from "react";
import ReactDOM from "react-dom";
import lambdaClientFactory from "./helpers/LambdaClientFactory";
import s3ClientFactory from "./helpers/S3ClientFactory";
import App from "./App";
import { PasteDataProvider } from "./context/PasteDataContext";

const params = new URLSearchParams(window.location.hash.slice(1));
const idToken = params.get("id_token");
let lambdaClients = lambdaClientFactory(idToken);
let s3Clients = s3ClientFactory(idToken);

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <PasteDataProvider>
        <App
          isSignedIn={idToken !== null}
          token={idToken}
          {...lambdaClients}
          {...s3Clients}
        />
      </PasteDataProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

render();
