import React, { useState } from 'react';
import './layout.css'; // Import a CSS file for custom styling
import Navbar from './Navbar';
import { toast } from 'react-toastify';
import ApiService from '../helpers/Services';

const Layout = ({ token }) => {
  const [email, setEmail] = useState('');
  const [learningId, setLearningId] = useState('');
  const [accessCode, setAccessCode] = useState('');

  // Example config list
  const learningOptions = [
    { LearningId: 'deb5f4bd-260a-4389-b549-ff692f7671c1', LearningName: 'Bioinformatics' },
    { LearningId: 'zeb5f4bd-260a-4389-b549-ff692f7671c1 ', LearningName: 'AI/ML' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        action: "POST-generate-access-code",
        body: {
          "createdAt": new Date().toISOString(),
          "createdBy": "u-GymHrZlI8_M-reIY-0M1V",
          "email": email,
          "learningId": learningId,
          "paymentType": "Online",
          "discountCode": "FullPrice",
          "paymentDate": new Date().toISOString()
        }
      };
      const response = await ApiService.post('generate-access-code', payload, headers);
      setAccessCode(response.code); // Adjust according to the actual response structure
      toast.success('Access code received successfully!');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className='layout-container'>
      <Navbar />
      <div className='content'>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Email ID:</label>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Learning Option:</label>
            <select
              value={learningId}
              onChange={(e) => setLearningId(e.target.value)}
              required
            >
              <option value=''>Select an option</option>
              {learningOptions.map((option) => (
                <option key={option.LearningId} value={option.LearningId}>
                  {option.LearningName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="submit-button" style={{ color: 'black', backgroundColor: 'purple' }}>
            Submit
          </button>        </form>
        {accessCode && <p>Access Code: {accessCode}</p>}
      </div>
    </div>
  );
};

export default Layout;
