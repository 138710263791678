const Config = {
  StackName: 'prod-sdo-admin-portal',
  DefaultImageName: 'default_document_3.png',
  CognitoIdentityPoolId: 'us-east-1:ff927121-1a2f-47f3-a6a0-a97e1257ffab',
  CognitoUserPoolId: 'us-east-1_OFNH2A8wo',
  DeployRegion: 'us-east-1',
  CognitoId: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_OFNH2A8wo',
  APIUrl: 'https://qmyidy6t5i.execute-api.us-east-1.amazonaws.com/prod',
  LogoutUrl: 'https://prod-sdo-admin-app-stack-id-provider.auth.us-east-1.amazoncognito.com/logout?client_id=77ulpd4104ktl1u5ph93dl158n&logout_uri=https://admin.dataocean.stanford.edu&response_type=token',
  LoginUrl: 'https://prod-sdo-admin-app-stack-id-provider.auth.us-east-1.amazoncognito.com/login?client_id=77ulpd4104ktl1u5ph93dl158n&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://admin.dataocean.stanford.edu'
};

export default Config;
