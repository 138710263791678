import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import Config from "../Config";

const getNextDocument = (client) => async (index) => {
  const input = {
    FunctionName: Config.DocumentStorageLambdaArn,
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      taskType: "CheckAnalyses",
      idx: index,
    }),
  };
  const command = new InvokeCommand(input);

  var response = await client.send(command);
  if (response.StatusCode === 200) {
    return JSON.parse(Buffer.from(response.Payload).toString()).body;
  } else throw Error(Buffer.from(response.Payload).toString());
};

const searchDocument = (client) => async (bucket, key) => {
  const input = {
    FunctionName: Config.DocumentStorageLambdaArn,
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      taskType: "CheckAnalyses",
      bucket: bucket,
      key: key,
    }),
  };
  const command = new InvokeCommand(input);

  var response = await client.send(command);
  if (response.StatusCode === 200) {
    return JSON.parse(Buffer.from(response.Payload).toString()).body;
  } else throw Error(Buffer.from(response.Payload).toString());
};

const analyzeDocument = (client) => async (bucket, key) => {
  const input = {
    FunctionName: Config.DocumentAnalyzerLambdaArn,
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      bucket: bucket,
      key: key,
    }),
  };
  const command = new InvokeCommand(input);

  var response = await client.send(command);
  if (response.StatusCode === 200) {
    return JSON.parse(Buffer.from(response.Payload).toString()).body;
  } else throw Error(Buffer.from(response.Payload).toString());
};

const lambdaClientFactory = (token) => {
  const creds = fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: Config.DeployRegion }),
    identityPoolId: Config.CognitoIdentityPoolId,
    logins: { [Config.CognitoId]: token },
  });

  const client = new LambdaClient({
    region: Config.DeployRegion,
    credentials: creds,
  });

  return {
    getNextDocument: getNextDocument(client),
    searchDocument: searchDocument(client),
    analyzeDocument: analyzeDocument(client),
  };
};

export default lambdaClientFactory;
