import { createContext, useState } from "react";

export const PasteDataContext = createContext({});

export const PasteDataProvider = ({ children }) => {
  const [pasteData, setPasteData] = useState(null);

  return (
    <PasteDataContext.Provider value={{ pasteData, setPasteData }}>
      {children}
    </PasteDataContext.Provider>
  );
};

export default PasteDataContext;
